import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ] // jshint ignore:line

  show_manager_form(event){
    var div = document.getElementById("manager_form");
    var tech_company_div = document.getElementById("tech_company");
    var tech_company_id = document.getElementById("tech_company_id");
    $(div).toggle();
    $(tech_company_div).toggle();
    if(tech_company_div.getAttribute("style")){
      tech_company_id.setAttribute("disabled","disabled");
    }else{
      tech_company_id.removeAttribute("disabled");
    }
  }

  show_tech_form(event){
    var div = document.getElementById("tech_form");
    $(div).toggle();
  }
}
