import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output", "calculated", "techAndMonitor", "techOnly", "managerOnly", "techAndMonitorCount", "techCount", "managerCount" ] // jshint ignore:line

  full_calculation(tech_and_monitor){
    var total=  1995 * tech_and_monitor
    this.calculatedTarget.textContent = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  tech_and_monitor_calculation(val){
    if(val > 0){
      var amount = 1995 * val;
      this.techAndMonitorTarget.textContent = amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
    else{
      this.techAndMonitorTarget.textContent = 0;
    }
  }

  update_cpro_max(tech_and_monitor)
  {
    var redeem_el = document.getElementById("redeem_amount");
    if(redeem_el)
    {
      var redeemable_cpro_amount = Math.floor(redeem_el.getAttribute("data-temp"));
      var total = 1995 * tech_and_monitor

      if (redeemable_cpro_amount < total)
      {
        redeem_el.setAttribute("max", redeemable_cpro_amount);
      }
      else{
        redeem_el.setAttribute("max", total);
      }
    }
  }

  calculate(){
    var tech_and_monitor = document.getElementById("tech_and_monitor");
    var tech_and_monitor_value = tech_and_monitor.options[tech_and_monitor.selectedIndex].value;
    this.tech_and_monitor_calculation(tech_and_monitor_value);
    Cookies.set('tech_and_monitor', tech_and_monitor_value);
    this.full_calculation(tech_and_monitor_value);
    this.techAndMonitorCountTarget.textContent = tech_and_monitor_value;
    this.update_cpro_max(tech_and_monitor_value);
  }

  connect(){
    var tech_and_monitor = Cookies.get('tech_and_monitor');
    if (typeof tech_and_monitor == "undefined")
    {
      tech_and_monitor = 1;
    }
    var tech_and_monitor_select = document.getElementById("tech_and_monitor");
    tech_and_monitor_select.value = tech_and_monitor;
    this.tech_and_monitor_calculation(tech_and_monitor);
    this.full_calculation(tech_and_monitor);
    this.techAndMonitorCountTarget.textContent = tech_and_monitor;
    Cookies.set('tech_and_monitor', tech_and_monitor);
  }

}
