import { ApplicationController } from "../support/application-controller";
import Rails from "rails-ujs";

export default class extends ApplicationController {
  static targets = [ "manager", "techNoMonitor", "techWithMonitor" ] // jshint ignore:line

  addManager(){
    var newElement = $(this.managerTargets[0]).clone(true);
    var count = this.managerTargets.length;
    newElement = this.updateFieldIncrement(newElement, count);
    newElement.insertAfter(".manager:last");
  }

  removeManager(){
    if (this.managerTargets.length === 1){return};
    var element = this.managerTargets[this.managerTargets.length - 1];
    $(element).remove();
  }

  addTechWithMonitor(){
    var newElement = $(this.techWithMonitorTargets[0]).clone(true);
    var count = this.techWithMonitorTargets.length;
    newElement = this.updateFieldIncrement(newElement, count);
    newElement.insertAfter(".tech-monitor:last");
  }

  removeTechWithMonitor(){
    if (this.techWithMonitorTargets.length === 1){return}
    var element = this.techWithMonitorTargets[this.techWithMonitorTargets.length -1];
    $(element).remove();
  }

  addTechWithoutMonitor(){
    var newElement = $(this.techNoMonitorTargets[0]).clone(true);
    var count = this.techNoMonitorTargets.length;
    newElement = this.updateFieldIncrement(newElement, count);
    newElement.insertAfter(".tech-no-monitor:last");
  }

  removeTechWithoutMonitor(){
    if (this.techNoMonitorTargets.length === 1){return}
    var element = this.techNoMonitorTargets[this.techNoMonitorTargets.length -1];
    $(element).remove();
  }

  addNewAttendee(){
    event.preventDefault()
    var newElement = $(this.techWithMonitorTargets[0]).clone(true);
    var count = this.techWithMonitorTargets.length;
    newElement = this.updateFieldIncrement(newElement, count);
    newElement.insertAfter(".attendee-row:last");
  }

  removeNewAttendee(){
    if (this.techWithMonitorTargets.length === 1){return}
    var element = this.techWithMonitorTargets[this.techWithMonitorTargets.length -1];
    $(element).remove();
  }

  updateFieldIncrement(element, count){
    var textFields = element.find('input[type=text], textarea, select');
    textFields.each(function(){
      var splitId = $(this).attr('id').split(/\d+/);
      var splitName = $(this).attr('name').split(/\d+/);
      $(this).attr('id', splitId[0] + count + splitId[1]);
      $(this).attr('name', splitName[0] + count + splitName[1]);
      $(this).val("");
    });

    return element;
  }
}
