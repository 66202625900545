import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output", "calculated" ] // jshint ignore:line

  full_calculation(tech_and_monitor){
    return 1995 * tech_and_monitor
  }

  calculate(){
    var tech_and_monitor = document.getElementById("tech_and_monitor");
    var tech_and_monitor_value = tech_and_monitor.options[tech_and_monitor.selectedIndex].value;
    Cookies.set('tech_and_monitor', tech_and_monitor_value);
    Cookies.set('stripe_coupon_amount', 0);
    Cookies.set('stripe_coupon_validity', false);
    Cookies.set("cpro_amount","");
    Cookies.set("cpro_validity", false);
    var total = this.full_calculation(tech_and_monitor_value)
    console.log(total);
    this.calculatedTarget.textContent = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

}
