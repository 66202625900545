import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output", "calculated" ] // jshint ignore:line

  show_form(){
    $("#shipping_address_form").toggle();
    $("#shipping_address").val($("#company_address_1").val());
    $("#shipping_city").val($("#company_city").val());
    $("#shipping_zip").val($("#company_zip").val());
  }

}
